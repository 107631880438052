
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 8888;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Content {
  max-width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  // border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  position: absolute;
  padding: 40px;

  @media (min-width: $screen-sm) {
    height: auto;
    max-height: 90%;
    top: 50%;
    left: 50%;
    padding: 40px 20px;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &.form-popup {
    max-width: 860px;
  }

  .close-btn {
    top: 0;
    right: 0;
    width: 51px;
    height: 51px;
    background: #000;
    overflow: hidden;
    position: absolute;
    font-style: normal;
    cursor: pointer;

    &:before {
      font-family: 'icomoon';
      content: '\e91f';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 45px;
      color: #fff;
    }
  }
}
