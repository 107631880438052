// @include font-face(icomoon, './../fonts/icomoon_lite', 500, normal, eot woff ttf svg);

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//temporary fix
.icon.material-icons {
  font-family: 'Material Icons';
  &.material-icons-outlined {
    font-family: 'Material Icons Outlined';
  }
}

.font-icons {
  @include font-icons();
}

.dive-resort-icon:before {
  content: "\e900";
}

.dive-center-icon:before {
  content: "\e901";
}

.best-choice-icon:before {
  content: "\e902";
}

.dollar-icon:before {
  content: "\e903";
}

.safety-icon:before {
  content: "\e904";
}

.people-icon:before {
  content: "\e905";
}

.find-icon:before {
  content: "\e906";
}

.general-photo-icon:before {
  content: "\e907";
}

.heart-icon:before {
  content: "\e908";
}

.seaweed-icon:before {
  content: "\e909";
}

.shield-icon:before {
  content: "\e90a";
}

.certificate-icon:before {
  content: "\e90b";
}

.oxigen-icon:before {
  content: "\e90c";
}

.car-icon:before {
  content: "\e90d";
}

.nitrox-icon:before {
  content: "\e90e";
}

.cube-icon:before {
  content: "\e90f";
}

.location-icon:before {
  content: "\e910";
}

.star-icon-active:before {
  content: "\e911";
}

.dialog-icon:before {
  content: "\e912";
}

.envelop-icon:before {
  content: "\e913";
}

.calendar-icon:before {
  content: "\e914";
}

.icon-icons-v2kopia-61:before {
  content: "\e915";
}

.peoples-icon:before {
  content: "\e916";
}

.last-renovation-icon:before {
  content: "\e917";
}

.itinerary-icon:before {
  content: "\e918";
}

.map-icon:before {
  content: "\e919";
}

.divers-icon:before {
  content: "\e91a";
}

.liveaboard-icon:before {
  content: "\e91b";
}

.dive-center-icon2:before {
  content: "\e91c";
}

.cart-icon:before {
  content: "\e91d";
}

.edit-icon:before {
  content: "\e91e";
}

.close-icon:before {
  content: "\e91f";
}

.letter-h-icon:before {
  content: "\e920";
}

.boat-layout-icon:before {
  content: "\e921";
}

.left-arrow-icon:before {
  content: "\e922";
}

.right-arrow-icon:before {
  content: "\e923";
}

.top-arrow-icon:before {
  content: "\e924";
}

.bottom-arrow-icon:before {
  content: "\e925";
}

.cake-icon:before {
  content: "\e926";
}

.underwater-icon:before {
  content: "\e927";
}

.trash-icon:before {
  content: "\e928";
}

.cave-icon:before {
  content: "\e929";
}

.drift-icon:before {
  content: "\e92a";
}

.ice-icon:before {
  content: "\e92b";
}

.wall-icon:before {
  content: "\e92c";
}

.wreck-icon:before {
  content: "\e92d";
}

.shark-icon:before {
  content: "\e92e";
}

.icon-icons-v2kopia-31:before {
  content: "\e92f";
}

.room-icon:before {
  content: "\e930";
}

.ships-icon:before {
  content: "\e931";
}

.material-icon:before {
  content: "\e932";
}

.icon-icons-v2kopia-36:before {
  content: "\e933";
}

.dimension-icon:before {
  content: "\e934";
}

.speed-icon:before {
  content: "\e935";
}

.crew-icon:before {
  content: "\e936";
}

.gift-icon:before {
  content: "\e937";
}

.list-icon:before {
  content: "\e938";
}

.grid-icon:before {
  content: "\e939";
}

.icon-icons-v2kopia-48:before {
  content: "\e93a";
}

.icon-star-outline:before {
  content: "\e93b";
}

.ok-icon-square:before {
  content: "\e93c";
}

.phone-icon:before {
  content: "\e93d";
}

.icon-link-bordered:before {
  content: "\e93e";
}

.ok-icon:before {
  content: "\e93f";
}

.fail-icon:before {
  content: "\e940";
}

.info-icon:before {
  content: "\e941";
}

.icon-warn:before {
  content: "\e942";
}

.manta-icon:before {
  content: "\e943";
}

.icon-moon:before {
  content: "\e944";
}

.icon-anchor:before {
  content: "\e945";
}

.icon-warning:before {
  content: "\e946";
}

.icon-clock:before {
  content: "\e947";
}

.icon-plane:before {
  content: "\e948";
}

.icon-thermometer:before {
  content: "\e949";
}

.icon-visibility:before {
  content: "\e94a";
}

.icon-depth:before {
  content: "\e94b";
}

.icon-merge:before {
  content: "\e94c";
}

.icon-shark-whale:before {
  content: "\e94d";
}

.icon-information:before {
  content: "\e94e";
}

.icon-arrow-right:before {
  content: "\e94f";
}

.snorkeling-icon:before {
  content: "\e950";
}

.check-icon:before {
  content: '\e951';
}

.luxury-liveaboard:before {
  content: '\e952';
}

.motorboat:before {
  content: '\e953';
}

.sailing-boat:before {
  content: '\e954';
}

.finish-icon:before {
  content: '\e955';
}

.copy-icon:before {
  content: '\e956';
}

.icon-star:before {
  content: "\e957";
}

.icon-tick:before {
  content: "\e958";
}

.icon-insurance:before {
  content: "\e959";
}

.new-cabin-layout:before {
  content: "\e95a";
}

.chat-icon:before {
  content: "\e95b";
}

.callback-icon:before {
  content: "\e95c";
}

.africa-icon:before {
  content: "\e963";
}

.south-america-icon:before {
  content: "\e964";
}

.asia-icon:before {
  content: "\e965";
}

.oceania-icon:before {
  content: "\e966";
}

.north-america-icon:before {
  content: "\e967";
}

.europe-icon:before {
  content: "\e968";
}

.sale-icon:before {
  content: '\e960';
}

.plane-icon:before {
  content: '\e961';
}

.fees-icon:before {
  content: '\e962';
}

.bulb-icon:before {
  content: '\e95e';
}

.play-icon:before {
  content: "\ea15";
}

.right-top-arrow:before {
  content: '\e95f';
}

.icon-choice:before {
  content: '\e96e';
}

.icon-flexibility:before {
  content: '\e96f';
}

.icon-discount:before {
  content: '\e970';
}

.icon-dice:before {
  content: '\e971';
}

.icon-diamond:before {
  content: '\e972';
}

.icon-concierge:before {
  content: '\e973';
}

.icon-control:before {
  content: '\e969';
}

.icon-agency:before {
  content: '\e96a';
}

.icon-partner:before {
  content: '\e96b';
}

.icon-network:before {
  content: '\e96c';
}

.icon-hammock:before {
  content: '\e96d';
}

.icon-wifi:before {
  content: '\e975';
}

.rental-icon:before {
  content: '\e976';
}

.length-icon:before {
  content: '\e977';
}

.route-icon:before {
  content: '\e978';
}

.book-icon:before {
  content: '\e979';
}

.discount-icon:before {
  content: '\e97a';
}

.suitcase-icon:before {
  content: '\e97b';
}

.home-icon:before {
  content: '\e97c';
}

.chat-icon2:before {
  content: '\e981'
}

.money-icon:before {
  content: '\e982';
}

.world-icon:before {
  content: '\e983';
}

.monitor-icon:before {
  content: '\e984';
}

.file-icon:before {
  content: '\e97d';
}

.ok-icon-new:before {
  content: "\e97f";
}

.fail-icon-new:before {
  content: "\e97e";
}

.warning-icon-new:before {
  content: '\e980';
}

.circle-empty-icon:before {
  content: '\e985';
}

.round-icon:before {
  content: '\e986';
}

.instagram-icon:before {
  content: '\e988';
}

.fb-icon:before {
  content: '\e987';
}

.twitter-icon:before {
  content: '\e989';
}

.youtube-icon:before {
  content: '\e98a';
}

.icon-money:before {
  content: '\e98b';
}

.icon-network-new:before {
  content: '\e98c';
}

.icon-register:before {
  content: '\e98d';
}

.icon-register-pending:before {
  content: '\e98e';
}

.icon-allincl:before {
  content: '\e98f';
}

.icon-breakfast:before {
  content: '\e990';
}

.icon-halfboard:before {
  content: '\e991';
}

.icon-condition:before {
  content: '\e992';
}

.icon-shower:before {
  content: '\e993';
}

.icon-welness:before {
  content: '\e994';
}

.icon-padi:before {
  content: '\e995';
}

.icon-transfer:before {
  content: '\e996';
}

.icon-padi-globe:before {
  content: '\e997';
}

.icon-highlights:before {
  content: '\e9a3';
}

.icon-dive-sites:before {
  content: '\e9a4';
}

.icon-filters:before {
  content: '\e95d';
}

.icon-sorting:before {
  content: '\e998';
}

.icon-stars:before {
  content: '\e999';
}

.icon-link:before {
  content: '\e9cb';
}

.icon-share:before {
  content: '\ea82';
}
.icon-google-plus:before {
  content: '\e99c';
}

.icon-linkedin:before {
  content: '\e99b';
}

.icon-pinterest:before {
  content: '\e99a';
}

.icon-plus:before {
  content: '\e99d';
}

.icon-virtual:before {
  content: '\e99e';
}

.icon-surcharges:before {
  content: '\e99f';
}

.icon-local-dc:before {
  content: "\e9a0";
}

.icon-return:before {
  content: "\e9a1";
}

.icon-transfer-from:before {
  content: "\e9a2";
}

.icon-transfer-to:before { 
  content: "\e9a5";
}

.icon-checkmark:before {
  content: "\e9a6";
}

.icon-credit-card:before {
  content: "\e9a7";
}

.icon-padi-small:before {
  content: "\e903";
}

.icon-thumbs-up:before {
  content: '\e90b';
}

.icon-thumbs-down:before {
  content: '\e904';
}

.icon-not-allowed:before {
  content: '\e90c';
}

.icon-question:before {
  content: "\e9a8";
}

.icon-sound:before {
  content: "\e9a9";
}

.icon-voucher:before {
  content: "\e90d";
}

.icon-pickup:before {
  content: "\e90f";
}

.icon-lightning:before {
  content: "\e915";
}

.icon-duration:before {
  content: "\e917";
}

.icon-medical:before {
  content: "\e918";
}

.icon-course:before {
  content: "\e91c"
}

.icon-diver:before {
  content: "\e920"
}

.icon-snorkeling:before {
  content: "\e92f"
}

.icon-settings:before {
  content: "\e921"
}

.icon-dive-center:before {
  content: "\e931"
}

.icon-history:before {
  content: "\e932"
}

.icon-profile:before {
  content: "\e933"
}

.target-icon:before {
  content: "\e9b3"
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-eye-blocked:before {
  content: "\e9d1";
}

.icon-thumbs-up-new:before {
  content: "\e934";
}

.icon-calendar-new:before {
  content: '\e935';
}
.icon-dive-types:before {
  content: "\e936";
}
.icon-diver-guides:before {
  content: "\e93a";
}
.icon-entry:before {
  content: "\e93c";
}
.icon-gear-suit:before {
  content: "\e93e";
}
.icon-map-marker:before {
  content: "\e963";
}
.icon-specialty-course:before {
  content: "\e964";
}
.icon-turtle:before {
  content: "\e965";
}
.icon-water-fresh:before {
  content: "\e966";
}
.icon-cart-guides:before {
  content: "\e967";
}
.icon-slack:before {
  content: "\e968";
}
.icon-share-dg:before {
  content: "\e96a";
}
.icon-email-new:before {
  content: "\e96b";
}

.icon-flounder-fish:before {
  content: "\e974";
}
.icon-diamond:before {
  content: "\e976";
}
.icon-people-group-dg:before {
  content: "\e97c";
}
.icon-stars-group:before {
  content: "\e986";
}
.icon-shield-approve:before {
  content: "\e96d";
}
.icon-thumbs-down-new:before {
  content: "\e98e";
}

.icon-google:before {
  content: "\e9a8";
}

.icon-champagne:before {
  content: "\e9aa";
}

.icon-check-all:before {
  content: "\e9ab";
}
