@import 'slick';
@import 'slick_theme';

.slick-pt-custom {
    width: 100%;
    &.space-between {
        .slick-list {
            margin-left: -15px;
        }

        .slick-slide {
            padding: 0 15px;
            box-sizing: border-box
        }
    }
    &.space-between-small {
        .slick-list {
            margin-left: -8px;
        }

        .slick-slide {
            padding: 0 8px;
            box-sizing: border-box
        }
    }

    &.space-between-center {
        .slick-list {
            margin-right: -30px;
        }

        .slick-slide {
            padding: 0 8px;
            box-sizing: border-box
        }
    }
    &.same-height {
        .slick-track {
            display: flex;
            align-items: stretch;
            justify-content: center;
            padding-bottom: 5px;
        }
        .slick-slide {
            height: auto;

            & > div {
                height: 100%;
            }
        }
        .slick-prev, .slick-next {
            top: 25%;
        }
    }
    .slick-dots {
        bottom: -40px;
        margin: 0;
        font-size: auto;

        li {
            margin: 0;
            width: 15px;
            height: 15px;

            button {
                width: 15px;
                height: 15px;
            }

            button:before {
                font-size: 32px;
            }
        }
    }

    .slick-arrow {
        width: 48px;
        height: 48px;
        background: #fff;
        box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        margin-top: -24px;
        z-index: 10;
        border-radius: 50%;
    }

    .slick-prev::before {
        content: '\e922';
        font-family: 'icomoon';
        color: $red-500;
        font-size: 44px;
    }
    .slick-next::before {
        color: $red-500;
        content: '\e923';
        font-family: 'icomoon';
        font-size: 44px;
    }
}
