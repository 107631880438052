@import 'mixins';
@import 'variables';
@import 'font-icons';
@import 'dsl-icons';
@import 'reset';
@import 'buttons';
@import 'typeahead';
@import 'react-datepicker';
@import 'form';
@import 'calendar';
@import 'slick_pt_custom';
@import 'react-modal';
@import 'phone';
@import 'loader';
@import 'tostify';

@include placeholder($gray-500);

@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 200dpi),
  only screen and (min-resolution: 1.25dppx) {
  html,
  body {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

/* noto-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
       url('../public/fonts/noto-sans-v27-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../public/fonts/noto-sans-v27-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-500 - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(''),
       url('../public/fonts/noto-sans-v27-latin_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../public/fonts/noto-sans-v27-latin_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-600 - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(''),
       url('../public/fonts/noto-sans-v27-latin_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../public/fonts/noto-sans-v27-latin_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
       url('../public/fonts/noto-sans-v27-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../public/fonts/noto-sans-v27-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@include font-face(icomoon, '../public/fonts/icomoon_lite', 500, normal, eot woff ttf svg);
@include font-face(dsl-icons, '../public/fonts/dsl-icons', 500, normal, eot woff ttf svg);

* {
  font-family: 'Noto Sans';
  line-height: 1.33;
}

p {
  margin: 0 0 10px;
}

.blue-bg {
  overflow: hidden;
  background: $blue-100;
}

.ptn {
  padding-top: 0 !important;
}
.pbn {
  padding-bottom: 0 !important;
}

.disable-scroll-mobile {
  @media (max-width: $screen-md) {
    overflow: hidden;
  }
}

.opened-popup {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .search-container {
    box-shadow: none;
    border: none;
    min-width: auto;
    max-height: calc(100vh - 80px);
    overflow: visible;
    position: static;
  }

  .close-icon-search {
    display: flex;
  }
  .location-menu-item {
    box-shadow: none;

    @include media('<=screen-sm') {
      height: calc(100vh - 70px);
      overflow-y: auto;
      padding: 0 16px;
    }
  }
  .location-items-list {
    columns: 1 !important;
  }
}

.hint {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: $gray-500;
}

.placeholder-animation {
  position: relative;
  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    -webkit-animation: phAnimation 0.8s linear infinite;
    animation: phAnimation 0.8s linear infinite;
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(46%, rgba(255, 255, 255, 0)),
        color-stop(50%, rgba(255, 255, 255, 0.35)),
        color-stop(54%, rgba(255, 255, 255, 0))
      )
      50% 50%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
  }
}


@-webkit-keyframes phAnimation {
  0% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}

input:not([type='range']) {
  padding: 8px 30px 7px 12px;
  border: 1px solid $gray-300;
  background: #f9f9f9;
  border-radius: 2px;
}

.input-with-icon {
  position: relative;
  input {
    padding-left: 35px;
  }

  .field-icon {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    margin-top: 3px;
    font-size: 25px;
  }
}


.mbm {
  margin-bottom: 10px;
}

#chat-buttons {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 0;

  .styled-chat-icon {
    position: fixed;
    background: $blue-500;
    color: #fff;
    border-radius: 4px !important;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.65);
    cursor: pointer;
    float: right;
    height: 48px;
    line-height: 50px;
    text-align: center;
    width: 48px;
    z-index: 9999;
    bottom: 30px;
    right: 30px;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    text-decoration: none;

    @include media('<screen-sm') {
      right: 14px;
    }

    &:focus,
    &:hover,
    &:active {
      transform: scale(1.1);
    }
  }

  a:not(.styled-chat-icon) {
    text-align: right;
    width: 150px;
    position: relative;
    display: block;
    text-transform: uppercase;
    line-height: 35px;
    font-family: 'Noto Sans';
    border-radius: 2px 0 0 2px;
    border-right: none;
    cursor: pointer;
    color: #fff;
    vertical-align: middle;
    margin-bottom: 10px;
    background: $blue-500;
    transition: all 0.3s linear;

    @media (max-width: $screen-md - 1) {
      width: auto;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      i {
        -webkit-animation: swing 1s;
        animation: swing 1s;
      }
    }
  }

  i {
    font-size: 24px;
    line-height: 48px;
    height: 48px;
    width: 48px;
    text-align: center;
  }
}
