.travel-calendar {
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 9999;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.5);
}
.travel-calendar.center-align .travel-calendar__input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.travel-calendar.right-align .travel-calendar__input-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.travel-calendar .week-days {
  display: none;
}
@media (max-width: 767px) {
  .travel-calendar {
    height: 0;
    transform: translateY(100%);
    transition: transform 0.3s linear;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .travel-calendar {
    display: none;
    justify-content: center;
    align-items: center;
  }
  .travel-calendar .travel-calendar__backdrop {
    background: rgba(60, 60, 60, 0.8);
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
  }
}
@media (min-width: 1025px) {
  .travel-calendar {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: -9999px;
    right: auto;
    top: 100%;
    transition: opacity 0.15s linear;
  }
  .travel-calendar-days-of-week {
    display: none;
  }
  .travel-calendar-month {
    margin: 0 20px;
  }
  .travel-calendar .week-days {
    display: flex;
  }
  .travel-calendar .week-days .travel-calendar-day {
    color: #f23d4e;
  }
}
.travel-calendar.visible {
  display: flex;
  position: fixed;
  overflow: auto;
  height: auto;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateY(0);
}
@media (min-width: 1025px) {
  .travel-calendar.visible {
    position: absolute;
    visibility: visible;
    opacity: 1;
    overflow: visible;
    left: 40px;
    bottom: auto;
    right: auto;
  }
}
.travel-calendar__input {
  height: 40px;
  margin: 8px;
  border: 2px solid #0088DC;
  padding-right: 12px;
}
.travel-calendar__hint {
  font-size: 12;
  color: #fff;
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1025px) {
  .travel-calendar__hint {
    color: #404040;
  }
}
.travel-calendar__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: #fff;
  position: relative;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .travel-calendar__container {
    width: 360px;
    border-radius: 3px;
    height: 56%;
    min-height: 550px;
  }
}
@media (min-width: 1025px) {
  .travel-calendar__container {
    border: 1px solid #eee;
    border-radius: 2px;
  }
}
.travel-calendar * {
  box-sizing: border-box;
}
.travel-calendar__header {
  width: 100%;
  min-height: 11%;
  border-bottom: 1px solid #eee;
}
.travel-calendar__header label {
  align-self: center;
  padding-left: 10px;
}
@media (max-width: 1024px) {
  .travel-calendar__header {
    background: #0088DC;
  }
  .travel-calendar__header label {
    display: none;
  }
}
@media (max-width: 1024px) {
  .travel-calendar__input-container {
    display: flex;
  }
  .travel-calendar__input-container input {
    width: 48%;
    margin: 3px 2%;
  }
}
.travel-calendar__error {
  width: 100%;
  margin: 5px 0;
  color: #f23d4e;
  justify-content: center;
  text-align: center;
  display: none;
}
.travel-calendar__error.active {
  display: flex;
  order: 2;
}
.travel-calendar__error p {
  border-radius: 3px;
  border: solid 1px #fbd0d4;
  background-color: #fef1f2;
  padding: 3px 15px;
  color: #91081f;
  margin: 0;
}
.travel-calendar__error p:before {
  content: '\e946';
  font-family: 'icomoon';
  vertical-align: middle;
  padding-right: 5px;
}
@media (max-width: 1024px) {
  .travel-calendar__error {
    font-size: 14px;
    background: #fff;
    padding: 5px 0;
    z-index: 2;
  }
}
.travel-calendar__error.active + .travel-calendar__hint {
  display: none;
}
.travel-calendar__hint {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}
.travel-calendar__hint p {
  margin: 0;
}
@media (max-width: 1024px) {
  .travel-calendar__duration-text-container {
    display: none;
  }
}
.travel-calendar__footer {
  width: 100%;
  border-top: 1px solid #eee;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  max-height: 17%;
  padding: 8px 20px;
  flex-wrap: wrap;
  order: 5;
}
.travel-calendar__footer .legend {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.travel-calendar__footer .legend .fixed-legend-container {
  margin-left: 20%;
}
@media (max-width: 1024px) {
  .travel-calendar__footer .legend {
    display: none;
  }
}
.travel-calendar__footer .legend-start-date, .travel-calendar__footer .legend-end-date {
  margin-bottom: 5px;
}
.travel-calendar__footer .legend-start-date:before, .travel-calendar__footer .legend-end-date:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #00dc1b;
  border-radius: 100%;
  margin-top: -2px;
}
.travel-calendar__footer .legend-end-date:before {
  border-color: #0024d7;
}
.travel-calendar__footer .legend-sold-out, .travel-calendar__footer .legend-available, .travel-calendar__footer .legend-no-info {
  position: relative;
  margin-bottom: 5px;
}
.travel-calendar__footer .legend-sold-out:before, .travel-calendar__footer .legend-available:before, .travel-calendar__footer .legend-no-info:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 15px;
  height: 15px;
  background: rgba(255, 0, 0, 0.3);
  border: 1px solid rgba(255, 0, 0, 0.7);
}
.travel-calendar__footer .legend-available:before {
  background: rgba(165, 236, 182, 0.2);
  border: 1px solid rgba(165, 236, 182, 0.8);
}
.travel-calendar__footer .legend-no-info::before {
  background: #e1e7e9;
  border: 1px solid #abaaaa;
}
.travel-calendar__clear_hint {
  color: #8d9aa5;
  font-size: 14px;
}
.travel-calendar__btn {
  line-height: 32px;
  padding: 0 15px;
  min-width: 100px;
  margin-left: 20px;
  max-height: 32px;
  align-self: center;
}
.travel-calendar__btn.close-btn {
  border-bottom: 1px solid #0088DC;
  padding: 0;
  line-height: 1;
  text-align: center;
  min-width: auto;
  color: #0088DC;
}
@media (max-width: 1024px) {
  .travel-calendar__btn {
    margin-left: 10px;
  }
  .travel-calendar__btn.close-btn {
    margin-left: 0;
  }
}
.travel-calendar__arrow {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #fafafa;
  color: #000;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.5);
  font-size: 30px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 1024px) {
  .travel-calendar__arrow {
    display: none;
  }
}
.travel-calendar__arrow.disabled {
  cursor: not-available;
  pointer-events: none;
  background: #f7f7f7;
  color: #bbb;
}
.travel-calendar__arrow.arrow-right {
  right: -20px;
}
.travel-calendar__arrow.arrow-left {
  left: -20px;
}
.travel-calendar-days-of-week {
  background: #ddd;
}
.travel-calendar-days-of-week .travel-calendar-day {
  border-color: #ddd;
}
.travel-calendar-monthes {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  padding: 0 10px;
  scroll-behavior: smooth;
  transition: transform 0.3s linear;
}
@media (min-width: 1025px) {
  .travel-calendar-monthes {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: visible;
  }
}
.travel-calendar-monthes-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 30%;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.travel-calendar-monthes-wrapper.highlight-range .travel-calendar-day:not(.possible-range-date) {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.35;
}
@media (min-width: 1025px) {
  .travel-calendar-monthes-wrapper {
    display: block;
    width: 730px;
    overflow: hidden;
    height: 65%;
  }
}
.travel-calendar-month {
  min-width: 220px;
  width: 100%;
}
@media (min-width: 1025px) {
  .travel-calendar-month {
    min-width: 320px;
  }
}
.travel-calendar-month:last-child {
  padding-bottom: 20px;
}
.travel-calendar-week {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.travel-calendar-day {
  position: relative;
  cursor: pointer;
  width: 14.285%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #fff;
}
.travel-calendar-day.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: #bbb;
}
@media (min-width: 1025px) {
  .travel-calendar-day {
    width: 45px;
    height: 45px;
  }
}
.travel-calendar-day:not(.disabled):hover.selected.utmost span {
  background: #fff;
  color: #000;
}
.travel-calendar-day:not(.disabled):hover span:before {
  content: '';
  position: absolute;
  border-radius: 100%;
  border: 1px solid #0088DC;
  bottom: 0;
  left: 0;
  height: initial;
  right: 0;
  top: 0;
  transform: scale(1);
  width: initial;
}
.travel-calendar-day span {
  position: relative;
  z-index: 2;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 100%;
  display: inline-block;
}
@media (min-width: 768px) {
  .travel-calendar-day span {
    width: 90%;
    height: 90%;
  }
}
.travel-calendar-day.selected {
  background: #e8f0fe;
  position: relative;
  transition: background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.travel-calendar-day.selected .blured-element {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}
.travel-calendar-day.selected .blured-element__start {
  background: linear-gradient(to right, #fff, #e8f0fe);
  left: -10px;
}
.travel-calendar-day.selected .blured-element__end {
  background: linear-gradient(to left, #fff, #e8f0fe);
  right: -10px;
}
.travel-calendar-day.selected.utmost .blured-element {
  display: none;
}
.travel-calendar-day.selected.utmost {
  background: none;
}
.travel-calendar-day.selected.utmost span {
  background: #0088DC;
  color: #fff;
}
.travel-calendar-day.selected.utmost-right {
  background: linear-gradient(to right, #fff, #e8f0fe);
}
.travel-calendar-day.selected.utmost-left {
  background: linear-gradient(to left, #fff, #e8f0fe);
}
.travel-calendar-day.available {
  background: rgba(165, 236, 182, 0.2);
}
.travel-calendar-day.available.utmost {
  background: rgba(165, 236, 182, 0.2);
}
.travel-calendar-day.available.selected {
  background: #e8f0fe;
  font-weight: bold;
  color: #008200;
}
.travel-calendar-day.not-available {
  background: rgba(255, 0, 0, 0.3);
}
.travel-calendar-day.not-available.utmost {
  background: rgba(255, 0, 0, 0.3);
}
.travel-calendar-day.not-available.selected {
  background: #e8f0fe;
  font-weight: bold;
  color: #f23d4e;
}
.travel-calendar-day.no-info {
  background: #e1e7e9;
}
.travel-calendar-day.no-info.utmost {
  background: #e1e7e9;
}
.travel-calendar-day.no-info.selected {
  background: #e8f0fe;
  font-weight: bold;
}
.travel-calendar-day.dow-available span:before {
  content: '';
  position: absolute;
  border-radius: 100%;
  border: 1px solid #00dc1b;
  bottom: 0;
  left: 0;
  height: initial;
  right: 0;
  top: 0;
  transform: scale(1);
  width: initial;
}
.travel-calendar-day.available.fixed-package-day:not(.dow-available):not(.selected) {
  background: #fff;
}
.travel-calendar-day.possible-range-date {
  background: rgba(165, 236, 182, 0.2);
}
.travel-calendar-day.possible-range-date span:before {
  content: '';
  position: absolute;
  border-radius: 100%;
  border: 1px solid #0024d7;
  background: rgba(19, 56, 239, 0.12);
  bottom: 0;
  left: 0;
  height: initial;
  right: 0;
  top: 0;
  transform: scale(1);
  width: initial;
}
.travel-calendar .month-name {
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
}
@media (min-width: 1025px) {
  .travel-calendar .month-name {
    text-align: center;
  }
}
.calendar-expanded {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.calendar-expanded body {
  overflow: hidden;
  position: relative;
  height: 100%;
}
