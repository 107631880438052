
.btn-red {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: $red-500;
  border: 1px solid $red-500;
  line-height: 1.5;
  min-height: 32px;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  text-decoration: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &.no-uppercase {
    text-transform: none;
  }

  &:hover,&:focus,&:active {
    background: $red-700;
    border-color: $red-700;
    color: #fff;
    text-decoration: none;
  }
  &:disabled, &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background: $red-500;
      border-color: $red-500;
    }
  }

  &.big-btn {
    min-width: 50%;
  }

  &.small-btn {
    line-height: 36px;
  }
  &::before,
  &::after {
    content: '';
    flex: 1 0 auto;
  }
}

.blue-btn {
  @extend .btn-red;

  background: $blue-500;
  border: 1px solid $blue-500;

  &:hover,&:focus,&:active {
    background: $blue-500;
    border-color: $blue-500;
  }
}

a.btn-red {
  text-align: center;
}

.btn-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: transparent;
  border: 1px solid #ccc;
  line-height: 1.5;
  min-height: 32px;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &:hover,
  &:focus {
    outline: none !important;
    text-decoration: none;
  }
}

.btn-red-invert {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: #fff;
  border: 1px solid $red-500;
  line-height: 1.5;
  min-height: 32px;
  color: $red-500;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  text-decoration: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &.small-btn {
    line-height: 36px;
  }

  &:hover {
    background-color: rgba($red-500,0.15);
    color: $red-500;
  }

  &:focus,&:active {
    outline: 5px auto rgb(244, 123, 123);
    outline-offset: -2px;
    color: $red-700;
    text-decoration: none;
  }
}

.grey-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: $gray-300;
  border: 1px solid $gray-300;
  line-height: 1.5;
  min-height: 32px;
  color: #000;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &:focus,&:active {
    outline: 5px auto #fff;
    outline-offset: -2px;
    text-decoration: none;
  }
}

.blue-btn-invert {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: transparent;
  border: 1px solid $blue-500;
  line-height: 1.5;
  min-height: 32px;
  color: $blue-500;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &:focus,&:active {
    outline: 5px auto #fff;
    outline-offset: -2px;
    text-decoration: none;
  }
}

.btn-red,.btn-red-invert {
  &.loading {
    overflow: hidden;
    position: relative;
    &:before {
      content: 'Loading...';
      background: #fff;
      color: $red-500;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      animation: pulse 1s linear infinite;
    }
  }
}

.btn-outline {
  color: $gray-900;
  line-height: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: #fff;
  border: 1px solid $red-500;
  line-height: 1.5;
  font-style: normal;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  background: none;
  transition: all 0.3s ease;
  text-decoration: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &:hover {
    background-color: rgba($red-500, 0.15);
    color: $gray-900;
    text-decoration: none;
  }
}

.btn-gray-invert {
  color: $gray-700;
  line-height: 32px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background: transparent;
  border: 1px solid $gray-300;
  line-height: 1.5;
  font-style: normal;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  vertical-align: top;
  background: none;
  transition: all 0.3s ease;
  text-decoration: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: inline-block;
  }

  &:hover {
    border-color: $gray-900;
    color: $gray-900;
    text-decoration: none;
  }
}

.delete-btn {
  @extend .btn-gray-invert;
  border: none;
  color: $blue-500
}


.btn-guides-blue{
  width: fit-content;
  padding: 0.75em 1em;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: solid 1px $blue-500;
  background-color: $blue-500;
}

.btn-guides-blue--invert{
  color: $blue-500;
  border: solid 1px $blue-500;
  background-color: transparent;
}

.btn-guides-gray{
  @extend .btn-guides-blue;
  color: $blue-900;
  border: solid 1px $gray-900;
  background-color: transparent;
}

.btn-borderless{
	border: none;
	background: none;
  cursor: pointer;
}

.btn-borderless-blue {
  @extend .btn-borderless;
  color: $blue-500;
}

.btn-borderless-red{
		@extend .btn-borderless;
		color: $red-500;
}

