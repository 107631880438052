$max-width: 1920px;
$screen-lg-shop: 1200px;
$screen-lg: 1400px;
$screen-md: 1024px;
$screen-sm: 768px;
$screen-xs: 480px;

//padi
$padi-grey: #696C6E;

$padi-blue: #f1f5f8;

$box-shadow-default: 0 4px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

// new colors from Ignas
$blue-900: #003059;
$blue-700: #1a50a7;
$blue-500: #0089de;
$blue-300: #ebf5fc;
$blue-100: #f0f5f8;

$gray-900: #333333;
$gray-800: #404040;
$gray-700: #495869;
$gray-500: #8d9aa5;
$gray-300: #dde3e6;
$gray-200: #f1f5f8;
$gray-100: #f5f7fa;

$green-700: #15937e;
$green-500: #2fbc83;
$green-300: #def7ed;

$red-900: #660000;
$red-700: #b81414;
$red-500: #f23d4e;

$yellow-700: #ea9134;
$yellow-500: #f2ba2c;

$header-color-dark: #4C4C4C;
$color-light-gray: #8494A4;

$link-color: #1e80bf;
$link-color-dark: #0066ff;

$default-text-color: #1a1c23;
