$dsl-font-family: "dsl-icons" !default;
$dsl-font-path: "../public/fonts" !default;

$dsl-icons--pro-diver: "\e903";
$dsl-icons--arrow-dropdown: "\e902";
$dsl-icons--star-marker: "\e901";
$dsl-icons--map-marker: "\e900";
$dsl-icons--tune: "\e949";
$dsl-icons--scuba-flag: "\e94a";
$dsl-icons--leaf: "\e94b";
$dsl-icons--layers: "\e94c";
$dsl-icons--inventory: "\e94d";
$dsl-icons--wine-dark: "\e94e";
$dsl-icons--wine-light: "\e94f";
$dsl-icons--web: "\e950";
$dsl-icons--unfold: "\e951";
$dsl-icons--turtle: "\e952";
$dsl-icons--translate: "\e953";
$dsl-icons--timer: "\e954";
$dsl-icons--store: "\e955";
$dsl-icons--snorkeling: "\e956";
$dsl-icons--snorkel-mask: "\e957";
$dsl-icons--scuba-tanks: "\e958";
$dsl-icons--scuba-tank: "\e959";
$dsl-icons--sail-boat: "\e95a";
$dsl-icons--plus: "\e95b";
$dsl-icons--plane: "\e95c";
$dsl-icons--phone: "\e95d";
$dsl-icons--minus: "\e95e";
$dsl-icons--map: "\e961";
$dsl-icons--list: "\e962";
$dsl-icons--information: "\e963";
$dsl-icons--folder-image: "\e964";
$dsl-icons--ferry: "\e965";
$dsl-icons--email: "\e966";
$dsl-icons--dots-vertical: "\e967";
$dsl-icons--dots-circle: "\e968";
$dsl-icons--flippers: "\e969";
$dsl-icons--deck: "\e96a";
$dsl-icons--credit-card: "\e96b";
$dsl-icons--close: "\e96c";
$dsl-icons--clock: "\e96d";
$dsl-icons--chevron-up: "\e96e";
$dsl-icons--chevron-right: "\e96f";
$dsl-icons--chevron-left: "\e970";
$dsl-icons--chevron-down: "\e911";
$dsl-icons--check: "\e910";
$dsl-icons--cash: "\e90f";
$dsl-icons--card: "\e90e";
$dsl-icons--calendar: "\e90d";
$dsl-icons--book: "\e90c";
$dsl-icons--bed: "\e90b";
$dsl-icons--beach: "\e90a";
$dsl-icons--badge-account: "\e909";
$dsl-icons--back: "\e908";
$dsl-icons--g-translate: "\e905";
$dsl-icons--groups: "\e904";
$dsl-icons--gps: "\e971";
$dsl-icons--flag: "\e972";
$dsl-icons--close-fill: "\e973";
$dsl-icons--arrow-expand: "\e974";

