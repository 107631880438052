.ripple-loader {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 8;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.ripple {
  display: inline-block;
  position: absolute;
  width: 128px;
  height: 128px;
  top: 50%;
  left: 50%;
  margin-left: -64px;
  margin-top: -64px;

  &.medium {
    width: 72px;
    height: 72px;
    margin-left: -36px;
    margin-top: -36px;
  }

  &.fixed {
    position: fixed;
  }
}

.ripple div {
  position: absolute;
  border: 2px solid $blue-500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 2.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  animation-delay: -0.7s;
}

.ripple div:nth-child(3) {
  animation-delay: -1.4s;
}

@keyframes ripple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
