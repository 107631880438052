.form-group {
  padding-bottom: toRem(30);

  &__hint {
    font-size: toRem(14);
    font-style: italic;
    color: #707F8C;
    margin-bottom: toRem(16);

    @include media('<=480px') {
      font-size: toRem(12);
    }

    &.additional-space {
      margin-top: toRem(8);
      margin-bottom: toRem(32);

      @include media('<=480px') {
        font-size: toRem(16);
      }
    }
  }

  &.fixed-form {
    max-width: toRem(768);
  }
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  grid-column-gap: toRem(25);

  @include media('<=480px') {
    grid-template-columns: 1fr;
  }

  &--gap-10 {
    grid-row-gap: toRem(10);
  }

  &--gap-20 {
    grid-row-gap: toRem(20);
  }

  &--with-margin {
    margin-top: toRem(16);

    @include media('<=480px') {
      margin-top: toRem(8);
    }
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  &.dynamic-cols {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    margin-bottom: toRem(16);


    @include media('<=480px') {
      grid-template-columns: 1fr;
    }
  }
}

.checkbox-area {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: toRem(24);
  grid-column-gap: toRem(24);
  padding: toRem(16) 0;

  &--two-column {
    grid-template-columns: 1fr 1fr;
  }

  @include media('<screen-sm') {
    grid-template-columns: 1fr 1fr;
  }

  @include media('<=480px') {
    grid-row-gap: toRem(16);
    grid-template-columns: 1fr;
  }

  &--columns {
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;

    @include media('<screen-sm') {
      grid-template-rows: repeat(8, 1fr)
    }

    @include media('<=480px') {
      grid-auto-flow: row;
    }
  }
}


.fr-box {
  z-index: 1;

  .fr-toolbar.fr-top {
    border-color: #ADC4D9 !important;
    border-radius: 6px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 1px solid #ADC4D9;
  }

  .fr-second-toolbar {
    border-color: #ADC4D9 !important;
    border-radius: 6px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  ul,
  ol {
    padding-left: toRem(15);
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}

.form-styled-checkboxes {
  display: flex;
  flex-wrap: wrap;
  column-gap: toRem(8);
  row-gap: toRem(8);
  padding-top: toRem(8);
  margin-bottom: toRem(16);

  @include media('<=screen-xs') {
    margin-bottom: toRem(8);
  }
}

.react-datepicker__input-container input {
  width: 100%;

  &[readonly] {
    background: #F4F8FB;
    color: #99ADBF;
    cursor: not-allowed;
  }
}

.react-datepicker-popper {
  font-size: toRem(14);

  .react-datepicker__navigation--previous {
    border-right-color: transparent !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: transparent !important;
  }
}
