@import "variables";

.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 100%;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  border-radius: 2px;
  background-color: #fff;
  height: 40px;
  font-size: 14px;
  color: #333;
  &--focused {
    outline: none;
  }
  &--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.react-autosuggest__suggestions-container {
  display: none;
  padding: 5px 0;
  font-size: 14px;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  background: #fff;

  &--open {
    display: block;
    width: 100%;
    z-index: 1000;
    position: absolute;
    min-width: 120px;
    border-radius: 0;
    border-top: 0;
    margin-top: -1px;
    border: none;
    min-width: 280px;
    right: 0;

    &:before {
      content: '';
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 95%;
      margin: 0 auto;
      background-color: $gray-300;
    }
  }
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;

  .typeahead-item {
    display: flex;
    padding: 3px 10px;
    align-items: flex-start;
  }
  .img-wrap {
    width: 48px;
    height: 48px;
    align-items: flex-start;
  }
  .font-icons {
    display: inline-block;
    font-size: 35px;
    width: 38px;
    height: 38px;
    line-height: 35px;
    color: $gray-500;
    &.target-icon {
      font-size: 20px;
      height: 30px;
      line-height: 30px;
    }
  }
  .last {
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 15px;
      right: 15px;
      height: 1px;
      background: $gray-300;
    }
  }
  &:last-child .last {
    margin-bottom: 0;
    &:before {
      content: none;
    }
  }
  .typeahead-title {
    font-size: 14px;
    color: #1a1c23;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &.without-icon {
      padding-left: 30px;
    }
  }
  .guide {
    .font-icons:before {
      font-size: 35px;
    }
  }
  .location-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .location-text {
    color: #8b8d8d;
    font-size: 14px;
    font-weight: 500;
  }
  .formatted-address {
    display: block;
    color: #8b8d8d;
    font-size: 12px;
    font-weight: normal;
  }
  &--highlighted {
    text-decoration: none;
    outline: 0;

    .font-icons,
    .formatted-address {
      color: #fff;
    }
    .typeahead-item {
      background-color: #FA777D;
      color: #fff;
    }
    .typeahead-title,
    .location-text,
    .typeahead-item {
      color: #fff;
    }
  }
}
.main-filters {
  .close-popup {
    width: 25px;
    height: 25px;
    position: absolute;
    transform: translateY(-50%);
    top: -150%;
    right: 20px;
    animation: 0.25s linear showCloseBtn;
    border-radius: 50%;
    border: 1px solid $gray-900;
    font-size: 27px;
    display: none;
    justify-content: center;
    align-items: center;
  }
}
.header-search-active {
  .toggle-menu {
    position: fixed;
    width: 100%;
    top: 150px;
    height: calc(175px + 100vh);
    transform: translateY(-150px);
    background-color: #fff;
    transition: 0.3s;
  }
  .close-popup {
    top: 50px;
    display: flex;
    z-index: 25;
  }
  .toggle-menu.mobile {
    .form-group-datepicker,
    .autosuggest-label > span,
    .btn-red,
    .search-title {
      opacity: 0;
      pointer-events: none;
    }
  }
}
.mobile-destination.search-block {
  .react-autosuggest {
    &__suggestions-container {
      box-shadow: none;
    }
    &__suggestion {
      padding-top: 10px;
    }
  }
}
