@import "dsl-icons-variables";


.dsl-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  @include dsl-icons();
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dsl-icons--pro-diver {
  &:before {
    content: $dsl-icons--pro-diver;
  }
}
.dsl-icons--arrow-dropdown {
  &:before {
    content: $dsl-icons--arrow-dropdown;
  }
}
.dsl-icons--star-marker {
  &:before {
    content: $dsl-icons--star-marker;
  }
}
.dsl-icons--map-marker {
  &:before {
    content: $dsl-icons--map-marker;
  }
}
.dsl-icons--tune {
  &:before {
    content: $dsl-icons--tune;
  }
}
.dsl-icons--scuba-flag {
  &:before {
    content: $dsl-icons--scuba-flag;
  }
}
.dsl-icons--leaf {
  &:before {
    content: $dsl-icons--leaf;
  }
}
.dsl-icons--layers {
  &:before {
    content: $dsl-icons--layers;
  }
}
.dsl-icons--inventory {
  &:before {
    content: $dsl-icons--inventory;
  }
}
.dsl-icons--wine-dark {
  &:before {
    content: $dsl-icons--wine-dark;
  }
}
.dsl-icons--wine-light {
  &:before {
    content: $dsl-icons--wine-light;
  }
}
.dsl-icons--web {
  &:before {
    content: $dsl-icons--web;
  }
}
.dsl-icons--unfold {
  &:before {
    content: $dsl-icons--unfold;
  }
}
.dsl-icons--turtle {
  &:before {
    content: $dsl-icons--turtle;
  }
}
.dsl-icons--translate {
  &:before {
    content: $dsl-icons--translate;
  }
}
.dsl-icons--timer {
  &:before {
    content: $dsl-icons--timer;
  }
}
.dsl-icons--store {
  &:before {
    content: $dsl-icons--store;
  }
}
.dsl-icons--snorkeling {
  &:before {
    content: $dsl-icons--snorkeling;
  }
}
.dsl-icons--snorkel-mask {
  &:before {
    content: $dsl-icons--snorkel-mask;
  }
}
.dsl-icons--scuba-tanks {
  &:before {
    content: $dsl-icons--scuba-tanks;
  }
}
.dsl-icons--scuba-tank {
  &:before {
    content: $dsl-icons--scuba-tank;
  }
}
.dsl-icons--sail-boat {
  &:before {
    content: $dsl-icons--sail-boat;
  }
}
.dsl-icons--plus {
  &:before {
    content: $dsl-icons--plus;
  }
}
.dsl-icons--plane {
  &:before {
    content: $dsl-icons--plane;
  }
}
.dsl-icons--phone {
  &:before {
    content: $dsl-icons--phone;
  }
}
.dsl-icons--minus {
  &:before {
    content: $dsl-icons--minus;
  }
}
.dsl-icons--map {
  &:before {
    content: $dsl-icons--map;
  }
}
.dsl-icons--list {
  &:before {
    content: $dsl-icons--list;
  }
}
.dsl-icons--information {
  &:before {
    content: $dsl-icons--information;
  }
}
.dsl-icons--folder-image {
  &:before {
    content: $dsl-icons--folder-image;
  }
}
.dsl-icons--ferry {
  &:before {
    content: $dsl-icons--ferry;
  }
}
.dsl-icons--email {
  &:before {
    content: $dsl-icons--email;
  }
}
.dsl-icons--dots-vertical {
  &:before {
    content: $dsl-icons--dots-vertical;
  }
}
.dsl-icons--dots-circle {
  &:before {
    content: $dsl-icons--dots-circle;
  }
}
.dsl-icons--flippers {
  &:before {
    content: $dsl-icons--flippers;
  }
}
.dsl-icons--deck {
  &:before {
    content: $dsl-icons--deck;
  }
}
.dsl-icons--credit-card {
  &:before {
    content: $dsl-icons--credit-card;
  }
}
.dsl-icons--close {
  &:before {
    content: $dsl-icons--close;
  }
}
.dsl-icons--clock {
  &:before {
    content: $dsl-icons--clock;
  }
}
.dsl-icons--chevron-up {
  &:before {
    content: $dsl-icons--chevron-up;
  }
}
.dsl-icons--chevron-right {
  &:before {
    content: $dsl-icons--chevron-right;
  }
}
.dsl-icons--chevron-left {
  &:before {
    content: $dsl-icons--chevron-left;
  }
}
.dsl-icons--chevron-down {
  &:before {
    content: $dsl-icons--chevron-down;
  }
}
.dsl-icons--check {
  &:before {
    content: $dsl-icons--check;
  }
}
.dsl-icons--cash {
  &:before {
    content: $dsl-icons--cash;
  }
}
.dsl-icons--card {
  &:before {
    content: $dsl-icons--card;
  }
}
.dsl-icons--calendar {
  &:before {
    content: $dsl-icons--calendar;
  }
}
.dsl-icons--book {
  &:before {
    content: $dsl-icons--book;
  }
}
.dsl-icons--bed {
  &:before {
    content: $dsl-icons--bed;
  }
}
.dsl-icons--beach {
  &:before {
    content: $dsl-icons--beach;
  }
}
.dsl-icons--badge-account {
  &:before {
    content: $dsl-icons--badge-account;
  }
}
.dsl-icons--back {
  &:before {
    content: $dsl-icons--back;
  }
}
.dsl-icons--g-translate {
  &:before {
    content: $dsl-icons--g-translate;
  }
}
.dsl-icons--groups {
  &:before {
    content: $dsl-icons--groups;
  }
}
.dsl-icons--gps {
  &:before {
    content: $dsl-icons--gps;
  }
}
.dsl-icons--flag {
  &:before {
    content: $dsl-icons--flag;
  }
}
.dsl-icons--close-fill {
  &:before {
    content: $dsl-icons--close-fill;
  }
}
.dsl-icons--arrow-expand {
  &:before {
    content: $dsl-icons--arrow-expand;
  }
}

